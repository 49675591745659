import { IconButton, Menu, MenuItem, Tooltip, Typography, SwipeableDrawer } from '@mui/material';
import Iconify from './Iconify';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import InvoicePDF from '../sections/@dashboard/invoice/details/InvoicePDF';
import React, { useState, useCallback } from 'react';

export default function PdfButtons({ data }) {
  const [anchorEl, setAnchorEl] = useState(null); // For the dropdown menu
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isPdfGenerated, setIsPdfGenerated] = useState(false);
  const [pdfFileName, setPdfFileName] = useState(''); // Store the PDF file name

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = async (data) => {
    const pdfBlob = await pdf(<InvoicePDF invoice={data} />).toBlob();
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = `${data?.fileName}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfUrl);
    handleMenuClose();
  };

  const handleViewPdf = () => {
    setDrawerOpen(true);
    setIsPdfGenerated(true);
    setPdfFileName(data?.fileName || 'Invoice');
    handleMenuClose();
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setIsPdfGenerated(false);
  };

  const renderPdf = useCallback(() => {
    if (isPdfGenerated) {
      return <InvoicePDF invoice={data} />;
    }
    return null;
  }, [isPdfGenerated, data]);

  return (
    <>
      <Tooltip title='PDF Options'>
        <IconButton size='small' color='error' onClick={handleMenuClick}>
          <Iconify icon='proicons:pdf' />
        </IconButton>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleDownload(data)}>
          <Iconify icon='line-md:download-loop' /> &nbsp;&nbsp;Download PDF
        </MenuItem>
        <MenuItem onClick={handleViewPdf}>
          <Iconify icon='lsicon:view-filled' /> &nbsp;&nbsp;View PDF
        </MenuItem>
      </Menu>

      <SwipeableDrawer
        anchor={'bottom'}
        open={drawerOpen}
        onOpen={() => setDrawerOpen(true)}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            width: '90%',
            height: '96%',
            marginLeft: '5%',
            borderRadius: '10px',
          },
        }}
      >
        <Typography variant='h6' sx={{ padding: '10px', textAlign: 'center' }}>
          {pdfFileName}
        </Typography>

        <PDFViewer style={{ width: '100%', height: '100vh' }}>{renderPdf()}</PDFViewer>
      </SwipeableDrawer>
    </>
  );
}
