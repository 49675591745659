import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { getSuppliers } from '../../../redux/slices/stockManagementRedux/supplierRedux';
import { addEnterPrice, getSupplierPricing } from '../../../redux/slices/purchaseRedux/purchaseRedux';
import { fCurrency } from '../../../utils/formatNumber';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../utils/common';
import PermissionRequired from '../../errorPages/permissionRequired';
import { searchProducts } from '../../../redux/slices/productRedux/productRedux';
import { toast } from 'react-toastify';

export default function CallAndGetPrices() {
  const { themeStretch } = useSettings();
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [supplierPricingList, setSupplierPricingList] = useState([]);
  const [dataModel, setDataModel] = useState({
    supplier: null,
  });
  const [updatingProductList, setUpdatingProductList] = useState([]);

  const { data, addData } = useSelector((state) => state?.enterPrices);
  let productsList;
  ({ data: productsList } = useSelector((state) => state?.product));
  let supplierData;
  ({ data: supplierData } = useSelector((state) => state?.supplier));

  useEffect(() => {
    dispatch(getSupplierPricing());
    dispatch(getSuppliers('stock'));
    dispatch(searchProducts('hasVariations', 0));
  }, []);

  useEffect(() => {
    setSupplierPricingList(
      data?.map((value) => {
        return {
          // ...value,
          datetime: utcMoment(value?.datetime).format('YYYY-MM-DD'),
          time: utcMoment(value?.datetime).format('HH:mm:ss'),
          productData: value?.productData,
          price: value?.price,
          productInfo: safeJSONParse(value?.productInfo)?.name,
          supplier: safeJSONParse(value?.supplier)?.name,
          userData: safeJSONParse(value?.userData)?.name,
        };
      })
    );
    setIsModelOpen(false);
  }, [data]);

  useEffect(() => {
    setUpdatingProductList(productsList?.map((value) => ({ ...value, purchasingPrice: 0 })));
  }, [productsList]);

  const updateDataModel = (key, value) => {
    setDataModel((prevDataModel) => ({
      ...prevDataModel,
      [key]: value,
    }));
  };

  const manageModel = (type) => {
    setDataModel({ supplier: null });
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      if (type === 'add') {
        setUpdatingProductList(productsList?.map((value) => ({ ...value, purchasingPrice: 0 })));
        setIsModelOpen(true);
        setIsAdd(type === 'add');
      }
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      if (!dataModel?.supplier?.id) {
        toast.error('Please select a supplier');
      } else {
        if (isAdd) {
          const addData = updatingProductList
            ?.filter((value) => value?.purchasingPrice > 0)
            .map((value) => {
              return {
                productData: JSON.stringify({
                  name: value?.name,
                  options: value?.productAttributesOptions,
                  maxPurchasingPrice: value?.maxPurchasingPrice,
                }),
                supplierId: dataModel?.supplier?.id,
                productId: value?.id,
                price: value?.purchasingPrice,
              };
            });
          dispatch(addEnterPrice(addData));
        }
        setIsLoading(false);
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getSupplierPricing());
      setIsLoading(false);
    }
  }, [addData]);

  const columns = !supplierPricingList?.[0]
    ? []
    : Object.keys(supplierPricingList?.[0])
        .map((value) => {
          if (['supplierId', 'productId', 'userId'].includes(value)) {
            return null;
          } else if (value === 'productData') {
            const productData = safeJSONParse(supplierPricingList?.[0]?.productData);
            console.log(productData);
            return {
              accessorKey: 'productData',
              header: 'Product Data',
              Cell: ({ cell, row }) => {
                return (
                  <div>
                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{productData?.name}</div>
                    <div style={{ fontSize: '12px', color: '#555' }}>
                      {productData?.options?.map((value) => {
                        return (
                          <span key={value?.productAttributeOptions?.id}>
                            <div>
                              {value?.productAttributeOptions?.productAttribute?.name} : {value?.productAttributeOptions?.name}
                            </div>
                          </span>
                        );
                      })}
                    </div>
                    <div style={{ fontSize: '12px', color: '#555', marginTop: '5px' }}>Max Purchasing: {fCurrency(productData?.maxPurchasingPrice)}</div>
                  </div>
                );
              },
            };
          }
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        })
        .filter((value) => value);

  const columnsForProductList = [
    {
      accessorKey: 'id',
      header: 'Product Id',
    },
    {
      accessorKey: 'name',
      header: 'Product Details',
      Cell: ({ cell, row }) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{row?.original?.name}</div>
            <div style={{ fontSize: '12px', color: '#555' }}>
              {row?.original?.productAttributesOptions?.map((value) => {
                return (
                  <span key={value?.productAttributeOptions?.id}>
                    <div>
                      {value?.productAttributeOptions?.productAttribute?.name} : {value?.productAttributeOptions?.name}
                    </div>
                  </span>
                );
              })}
            </div>
            <div style={{ fontSize: '12px', color: '#555', marginTop: '5px' }}>Max Purchasing: {fCurrency(row?.original?.maxPurchasingPrice)}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'purchasingPrice',
      header: 'Purchasing Price',
      Cell: ({ cell, row }) => {
        return (
          <div>
            <TextField
              type={'number'}
              autoFocus
              margin='normal'
              value={row?.original?.purchasingPrice}
              onChange={(event) => {
                setUpdatingProductList((prevState) => {
                  return prevState.map((value) => {
                    if (value?.id === row?.original?.id) {
                      return {
                        ...value,
                        purchasingPrice: event.target.value,
                      };
                    }
                    return value;
                  });
                });
              }}
            />
          </div>
        );
      },
    },
  ];

  const [rowSelection, setRowSelection] = useState(false);

  return (
    <Page title='Enter Checked Prices'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Enter Checked Prices'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Quotation',
              href: PATH_DASHBOARD.stocks.root,
            },
            { name: 'Enter Checked Prices' },
          ]}
          action={
            <Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />} onClick={() => manageModel('add')}>
              Add Checked Price
            </Button>
          }
        />

        {accessVerify('CALL_AND_GET_PRICES_VIEW') ? (
          <DataGridTable
            name={'Enter Prices'}
            data={supplierPricingList}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>Call & Get Prices</h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Supplier Information
                  </Typography>
                  <Stack spacing={3}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        updateDataModel('supplier', newValue);
                      }}
                      options={supplierData?.map((value) => {
                        return {
                          ...value,
                          label: `${value?.name} (${value?.code} - ${value?.phone})`,
                          value: value?.id,
                        };
                      })}
                      value={dataModel?.supplier?.name ? `${dataModel?.supplier?.name} (${dataModel?.supplier?.code} - ${dataModel?.supplier?.phone})` : null}
                      renderInput={(params) => <TextField label='Select Selling Items' {...params} />}
                    />
                  </Stack>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Prices For Each Product
                  </Typography>
                  <Stack spacing={3}>
                    <DataGridTable
                      name={'Call & Get prices Product list'}
                      data={updatingProductList}
                      column={columnsForProductList}
                      isExportable={false}
                      isRowClickable={false}
                      isLoading={false}
                      rowSelection={rowSelection}
                      setRowSelection={setRowSelection}
                      enableRowSelection={false}
                      enableRowActions={false}
                      renderRowActionItems={(value, closeMenu) => []}
                    />
                  </Stack>
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <LoadingButton onClick={onSubmit} variant='contained' size='large' style={{ width: '100%' }} loading={isLoading}>
                    {isAdd ? 'Add Prices' : 'Save Changes'}
                  </LoadingButton>
                  <Button color='info' variant='outlined' size='large' style={{ width: '100%' }} onClick={() => manageModel('add')}>
                    Close
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
