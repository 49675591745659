import moment from 'moment-timezone';
import { CDN_BASE_URL } from '../config';
import { toast } from 'react-toastify';
import { imageUrlUpload } from '../inteceptor';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Typography } from '@mui/material';

export const jsonParseArray = (value) => {
  try {
    return safeJSONParse(value) || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const capitalize = (str) => {
  if (!str || typeof str !== 'string') return '';

  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .split(' ')
    .map((segment) => {
      if (/^[0-9]+[a-zA-Z]+$/.test(segment)) {
        let [numbers, letters] = segment.match(/[a-zA-Z]+|[0-9]+/g);
        return `${numbers}${letters.toUpperCase()}`;
      } else if (/^[0-9]/.test(segment)) {
        return segment;
      } else {
        return segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase();
      }
    })
    .join(' ');
};

export const capitalizeAndEndWithPeriod = (str) => {
  if (!str || typeof str !== 'string') return '';

  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b([0-9]+)([a-zA-Z]+)/g, (match, p1, p2) => `${p1} ${p2.charAt(0).toUpperCase() + p2.slice(1).toLowerCase()}`)
    .toLowerCase()
    .split(' ')
    .map((segment) => (/^[0-9]+$/.test(segment) ? segment : segment.charAt(0).toUpperCase() + segment.slice(1)))
    .join(' ');
};

export const handleOpenInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};
export const handleOpenInNewWindow = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer,width=1200,height=800');
};

export const truncateStringValue = (str, maxLength) => {
  if (!str || typeof str !== 'string') return '';
  return str?.length > maxLength ? str?.slice(0, maxLength - 3) + '...' : str;
};

export const handleNumber = (value) => {
  if (!value) return 0;
  return isNaN(Number(value)) ? 0 : Number(value);
};

export const safeJSONParse = (value) => {
  if (!value) return null;
  try {
    return JSON.parse(value);
  } catch (e) {
    // console.error('Error parsing JSON:', e);
    return value;
  }
};

export const utcMomentFormatDate = (dateTime) => {
  if (!dateTime) return moment().utc();
  return moment(dateTime).utc().format('DD MMMM YYYY');
};

export const utcMomentFormatTime = (dateTime) => {
  if (!dateTime) return moment().utc();
  return moment(dateTime).utc().format('HH:mm');
};

export const utcMomentFormatDateTime = (dateTime) => {
  if (!dateTime) return moment().utc();
  return moment(dateTime).utc().format('DD MMMM YYYY, HH:mm');
};

export const utcMoment = (dateTime) => {
  if (!dateTime) return moment().utc();
  return moment(dateTime).utc();
};
export const padNumber = (num, length) => num.toString().padStart(length, '0');

export const getTwoDigitsNumber = (number) => {
  if (number === null || number === undefined) return '';
  return number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export const accessVerify = (code) => {
  const permissionList = safeJSONParse(localStorage.getItem('permissionsList')) || [];
  return permissionList.includes(code) || permissionList.includes('SUPER_ADMIN');
};

let isWidgetOpen = false;

export const uploadFiles = (url, publicId, name, folderLocation, formats) => {
  return new Promise((resolve, reject) => {
    imageUrlUpload(
      url,
      publicId,
      name,
      folderLocation,
      formats,
      (data) => {
        if (data?.status) {
          resolve(data);
        } else {
          reject(new Error('Upload failed'));
        }
      },
      (errorMessage) => {
        console.error('Upload failed:', errorMessage);
        reject(new Error(errorMessage));
      }
    );
  });
};

export const uploadImageToCloudinary = (name, width, height, folder = 'other', formats = ['png']) => {
  return new Promise((resolve, reject) => {
    if (isWidgetOpen) {
      toast.warning('Please wait, the upload widget is already open.');
      return;
    }
    isWidgetOpen = true;
    const croppingAspectRatio = width / height;

    let cloudinaryWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_PRESENT,
        clientAllowedFormats: ['jpg', 'jpeg', 'png', 'avif', 'webp'],
        multiple: false,
        cropping: true,
        croppingAspectRatio: croppingAspectRatio,
        showSkipCropButton: false,
      },
      (error, result) => {
        if (error) {
          console.error('Error:', error);
          toast.error('Error occurred while uploading the image!');
          reject(error);
        } else if (result && result.event === 'success') {
          const originalUrl = result.info.secure_url.replace('/upload/', `/upload/q_auto:eco,c_crop,g_custom/`);

          uploadFiles(originalUrl, result.info?.public_id, name, folder, formats)
            .then((value) => {
              resolve(value);
            })
            .catch((error) => {
              reject(error);
            });
        } else if (result && result.event === 'abort') {
          reject('close');
        }
        isWidgetOpen = false;
      }
    );

    cloudinaryWidget.open();
  });
};

export const getRequiredProductAttributesFromCategoryList = (productCategoryList = [], categoryId) => {
  let category = productCategoryList.find((category) => category.id === categoryId);
  if (!category) return [];
  let attributes = category.requiredProductAttributes || [];
  if (category.parentId !== null) {
    const parentAttributes = getRequiredProductAttributesFromCategoryList(productCategoryList, category.parentId);
    attributes = attributes.concat(parentAttributes);
  }
  return [...new Set(attributes.map((attr) => attr?.attributeId || attr))];
};

export const ImageUploadAndPreviewButton = ({
  fileName,
  folderName,
  format,
  label,
  width,
  height,
  keyName,
  isImageUploading,
  setIsImageUploading,
  updateEditingData,
  selectedDataObj,
  hideTopic = false,
}) => {
  const handleImageUpload = () => {
    setIsImageUploading(true);
    uploadImageToCloudinary(fileName, width, height, folderName, format)
      .then((data) => {
        setIsImageUploading(false);
        updateEditingData(keyName, data?.data?.publicUrl);
      })
      .catch((err) => {
        setIsImageUploading(false);
        if (err !== 'close') {
          toast.error('Unexpected error occurred!');
        }
      });
  };

  return (
    <>
      {!hideTopic && (
        <Typography variant='h6' gutterBottom>
          {label} Uploader
        </Typography>
      )}
      <LoadingButton
        variant='outlined'
        size='large'
        loading={isImageUploading}
        onClick={handleImageUpload}
        fullWidth={true}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          height: `${(height / width) * 300}px`,
          width: `${(width / width) * 300}px`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: selectedDataObj?.[keyName] ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
          '&:hover': {
            '.overlay': {
              opacity: 1,
            },
            '.text': {
              opacity: 1,
            },
          },
        }}
      >
        {selectedDataObj?.[keyName] && (
          <Box
            component='img'
            src={selectedDataObj?.[keyName]}
            alt={fileName}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: 1,
              opacity: 0.8,
            }}
          />
        )}

        {selectedDataObj?.[keyName] && (
          <Box
            className='overlay'
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              opacity: 0,
              transition: 'opacity 0.3s ease',
              zIndex: 2,
            }}
          />
        )}

        <Typography
          className='text'
          sx={{
            position: 'relative',
            zIndex: 3,
            color: selectedDataObj?.[keyName] ? '#fff' : 'inherit',
            opacity: selectedDataObj?.[keyName] ? 0 : 1,
            transition: 'opacity 0.3s ease',
          }}
        >
          {selectedDataObj?.[keyName] ? `ReUpload ${label} (${width}px x ${height}px)` : `Upload ${label} (${width}px x ${height}px)`}
        </Typography>

        {isImageUploading && (
          <CircularProgress
            size={40}
            sx={{
              color: '#fff',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-20px',
              marginLeft: '-20px',
              zIndex: 4,
            }}
          />
        )}
      </LoadingButton>
    </>
  );
};

export const handleImageUrl = (url) => url.replace('https://storage.googleapis.com/cdn.buyzone.lk/', CDN_BASE_URL);
