import React, { useEffect, useState } from 'react';
import { Autocomplete, Card, Stack, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getRequiredProductAttributesFromCategoryList } from '../../../../../utils/common';

export default function ProductAttributes({ selectedData, setSelectedDataObj }) {
  const [attributesList, setAttributesList] = useState([]);
  const { data } = useSelector((state) => state?.productAttributes);
  const productCategoryList = useSelector((state) => state.productCategory?.data);
  const requiredAttributes = getRequiredProductAttributesFromCategoryList(productCategoryList, selectedData?.categoryId);

  useEffect(() => {
    if (data) {
      setAttributesList(data?.filter((value) => requiredAttributes.includes(value.id)));
    }
  }, [data, productCategoryList]);

  const updateOrAddProductAttributeOption = (attributeId, newOption, options) => {
    let updatedOptions = [...(selectedData?.productAttributesOptions || [])];
    const existingAttributeIndex = updatedOptions.findIndex((option) => option.attributeId === attributeId);
    const selectedOptionValues = options?.find((value) => value?.id === newOption?.value);
    if (newOption) {
      if (existingAttributeIndex !== -1) {
        updatedOptions[existingAttributeIndex] = {
          ...updatedOptions[existingAttributeIndex],
          productAttributeOptionId: newOption?.value,
          id: newOption?.value,
          label: selectedOptionValues?.name,
        };
      } else {
        updatedOptions.push({
          attributeId,
          productAttributeOptionId: newOption?.value,
          id: newOption?.value,
          label: selectedOptionValues?.name,
        });
      }
    } else {
      updatedOptions = updatedOptions.filter((option) => option.attributeId !== attributeId);
    }

    setSelectedDataObj({
      ...selectedData,
      productAttributesOptions: updatedOptions,
    });
  };
  const formatLabel = (preUnit, name, postUnit) => {
    const pre = preUnit ? `${preUnit} ` : '';
    const post = postUnit ? ` ${postUnit}` : '';
    return `${pre}${name}${post}`;
  };
  return (
    <Card sx={{ p: 3 }} style={{ height: '100%' }}>
      <Typography variant='subtitle1' padding='10px 0'>
        Attribute Data
      </Typography>
      <Stack spacing={3}>
        {attributesList?.map((currentAttribute) => {
          const currentValueData = selectedData?.productAttributesOptions?.find((option) => option?.attributeId === currentAttribute?.id);

          return (
            <Autocomplete
              key={currentAttribute?.id}
              onChange={(event, newValue) => {
                updateOrAddProductAttributeOption(currentAttribute?.id, newValue, currentAttribute?.options);
              }}
              options={currentAttribute?.options?.map((value) => ({
                label: `${formatLabel(currentAttribute?.preUnit, value.name, currentAttribute?.postUnit)}`,
                value: value.id,
              }))}
              value={
                currentValueData
                  ? {
                      label: `${formatLabel(currentAttribute?.preUnit, currentValueData?.label, currentAttribute?.postUnit)}`,
                      value: currentValueData?.id,
                    }
                  : null
              }
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <TextField label={currentAttribute?.name} {...params} />}
            />
          );
        })}
      </Stack>
    </Card>
  );
}
