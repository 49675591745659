import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, Grid, Paper, Stack, SwipeableDrawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { getSupplierPricing } from '../../../redux/slices/purchaseRedux/purchaseRedux';
import { fCurrency } from '../../../utils/formatNumber';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../utils/common';
import { addPurchaseNotes } from '../../../redux/slices/purchaseRedux/purchaseNotesRedux';

export default function AddToCart() {
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [supplierPricingList, setSupplierPricingList] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [rowSelection, setRowSelection] = useState(false);

  const { selectedLocation } = useSelector((state) => state?.location);
  const { addData } = useSelector((state) => state?.purchaseNotes);
  const { data: enterPricesData } = useSelector((state) => state.enterPrices);

  useEffect(() => {
    dispatch(getSupplierPricing());
  }, [dispatch]);

  useEffect(() => {
    setSupplierPricingList(
      enterPricesData?.map((value) => ({
        qty: '',
        ...value,
        datetime: utcMoment(value?.datetime).format('YYYY-MM-DD'),
        time: utcMoment(value?.datetime).format('HH:mm:ss'),
        productInfo: safeJSONParse(value?.productInfo)?.name,
        supplier: safeJSONParse(value?.supplier)?.name,
        userData: safeJSONParse(value?.userData)?.name,
      }))
    );
    setIsModelOpen(false);
  }, [enterPricesData]);

  const updateQuantity = (id, key, qty) => {
    setSupplierPricingList((prevEnteredPricing) =>
      prevEnteredPricing.map((value) =>
        value.id === id
          ? {
              ...value,
              [key]: qty,
            }
          : value
      )
    );
  };

  const manageModel = (type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      if (type === 'add') {
        setIsModelOpen(true);
      }
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    setDataUpdated(true);
    const processedData = cartData?.map((supplierPurchaseNoteData) => {
      return {
        supplierId: supplierPurchaseNoteData?.supplierId,
        totalAmount: supplierPurchaseNoteData?.totalAmount,
        paymentForReceiving: supplierPurchaseNoteData?.totalAmount,
        paidAmount: 0,
        price: supplierPurchaseNoteData?.totalAmount,
        locationId: selectedLocation?.id,
        items: supplierPurchaseNoteData?.productList?.map((item) => {
          return {
            supplierPricingId: item?.id,
            qty: item?.qty,
            totalAmount: Number(item?.price) * Number(item?.qty),
          };
        }),
      };
    });
    try {
      dispatch(addPurchaseNotes(processedData));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getSupplierPricing());
      setIsLoading(false);
      if (dataUpdated) {
        navigate(PATH_DASHBOARD.sales.purchaseNotes);
      }
    }
  }, [addData]);

  const getNeededProductList = () => {
    const supplierProductPricingList = supplierPricingList?.filter((value) => value?.qty);

    const supplierList = Array.from(new Set(supplierProductPricingList.map((value) => value?.supplierId)));
    return supplierList?.map((supplierId) => {
      const supplierProductList = supplierProductPricingList
        ?.filter((retrievedProduct) => retrievedProduct?.supplierId === supplierId)
        ?.map((value) => ({ ...value, productData: safeJSONParse(value?.productData) }));
      const supplierName = supplierProductList?.[0]?.supplier;
      const totalAmount = supplierProductList?.reduce((total, product) => total + Number(product?.price) * Number(product?.qty || 0), 0);
      return {
        supplierId,
        supplierName,
        productList: supplierProductList,
        totalAmount,
      };
    });
  };
  const columns = !supplierPricingList?.[0]
    ? []
    : Object.keys(supplierPricingList?.[0])
        .map((value) => {
          if (['supplierId', 'productId', 'userId'].includes(value)) {
            return null;
          } else if (value === 'qty') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => (
                <TextField
                  type='number'
                  label={`Qty`}
                  value={row?.original[value] || ''}
                  onChange={(event) => updateQuantity(row?.original.id, value, event.target.value)}
                  onClick={(event) => {
                    event.target.select();
                  }}
                />
              ),
            };
          } else if (value === 'price') {
            return {
              accessorKey: value,
              header: 'Price Per Unit',
              Cell: ({ cell }) => fCurrency(cell.getValue()),
            };
          } else if (value === 'productData') {
            const productData = safeJSONParse(supplierPricingList?.[0]?.productData);
            return {
              accessorKey: 'productData',
              header: 'Product Data',
              Cell: ({ cell, row }) => {
                return (
                  <div>
                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{productData?.name}</div>
                    <div style={{ fontSize: '12px', color: '#555' }}>
                      {productData?.options?.map((value) => {
                        return (
                          <span key={value?.productAttributeOptions?.id}>
                            <div>
                              {value?.productAttributeOptions?.productAttribute?.name} : {value?.productAttributeOptions?.name}
                            </div>
                          </span>
                        );
                      })}
                    </div>
                    <div style={{ fontSize: '12px', color: '#555', marginTop: '5px' }}>Max Purchasing: {fCurrency(productData?.maxPurchasingPrice)}</div>
                  </div>
                );
              },
            };
          }
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        })
        ?.filter((value) => value);

  return (
    <Page title='Enter Checked Prices'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Enter Checked Prices'
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Quotation', href: PATH_DASHBOARD.stocks.root }, { name: 'Enter Checked Prices' }]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                const cartData = getNeededProductList();
                if (cartData?.length > 0) {
                  setCartData(cartData);
                  manageModel('add');
                } else {
                  toast.warning('Please enter purchase unit qty and store unit qty for at least one product!');
                }
              }}
            >
              Generate Purchase Notes
            </Button>
          }
        />
        {accessVerify('ADD_TO_CART_VIEW') && (
          <DataGridTable name={'Add To Cart'} data={supplierPricingList} column={columns} isLoading={false} rowSelection={rowSelection} setRowSelection={setRowSelection} enableRowActions={false} />
        )}
        <SwipeableDrawer
          anchor='top'
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>Generate Purchase Notes</h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              {cartData?.map((value, index) => (
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Card sx={{ p: 3 }}>
                    <h3>
                      Order For {value?.supplierName} - ({fCurrency(value?.totalAmount)})
                    </h3>
                    <hr />
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Price Per Unit</TableCell>
                            <TableCell>Purchase Unit Qty</TableCell>
                            <TableCell>Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {value?.productList?.map((supplierPriceNote) => (
                            <TableRow key={`${value?.supplierName}-${supplierPriceNote?.productData?.name}-${supplierPriceNote?.productInfo?.id}`}>
                              <TableCell>{supplierPriceNote?.id}</TableCell>
                              <TableCell>{supplierPriceNote?.productData?.name}</TableCell>
                              <TableCell>{fCurrency(supplierPriceNote?.price)}</TableCell>
                              <TableCell>{supplierPriceNote?.qty}</TableCell>
                              <TableCell>{fCurrency(supplierPriceNote?.price * supplierPriceNote?.qty)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Stack>
              ))}

              <Card sx={{ p: 3 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <LoadingButton onClick={onSubmit} variant='contained' size='large' style={{ width: '100%' }} loading={isLoading}>
                    Verify & Create Purchase Notes
                  </LoadingButton>
                  <Button color='info' variant='outlined' size='large' style={{ width: '100%' }} onClick={() => manageModel('add')}>
                    Close
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
