import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { accessVerify, capitalize, utcMoment } from '../../../utils/common';
import { addSupplier, getSuppliers, updateSupplier } from '../../../redux/slices/stockManagementRedux/supplierRedux';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import { imageUpload } from '../../../inteceptor';
import PermissionRequired from '../../errorPages/permissionRequired';
import { useParams } from 'react-router-dom';

export default function SupplierManagement() {
  const { type } = useParams();
  const dataModel = {
    id: '',
    name: '',
    code: '',
    email: '',
    phone: '',
    address: '',
    contactPersonName: '',
    contactPersonNumber: '',
    bank: '',
    bankAccountNumber: '',
    bankAccountName: '',
    bankBranchName: '',
    note: '',
  };
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const { themeStretch } = useSettings();
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [supplierList, setSupplierList] = useState([]);

  const { data, addData } = useSelector((state) => state.supplier);

  useEffect(() => {
    dispatch(getSuppliers(type));
  }, [type]);

  useEffect(() => {
    setSupplierList(
      data?.map((value) => {
        return {
          ...value,
          addedBy: value?.addedByUser?.name,
          lastUpdatedBy: value?.lastUpdatedByUser?.name,
        };
      })
    );
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getSuppliers(type));
    }
  }, [addData]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const columns = !supplierList?.[0]
    ? []
    : Object.keys(supplierList?.[0])
        .map((value) => {
          if (['log', 'addedByUser', 'lastUpdatedByUser'].includes(value)) {
            return {};
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  const [rowSelection, setRowSelection] = useState(false);

  const handleSubmit = () => {
    if (selectedDataObj.name.length === 0) {
      toast.error('Supplier name is required!');
    } else if (selectedDataObj.code.length === 0) {
      toast.error('Supplier code is required!');
    } else if (selectedDataObj.email.length === 0) {
      toast.error('Email is required!');
    } else if (selectedDataObj.phone.length === 0) {
      toast.error('Phone number is required!');
    } else if (selectedDataObj.address.length === 0) {
      toast.error('Address is required!');
    } else if (selectedDataObj.contactPersonName.length === 0) {
      toast.error('Contact person name is required!');
    } else if (selectedDataObj.contactPersonNumber.length === 0) {
      toast.error('Contact person number is required!');
    } else if (selectedDataObj.bank.length === 0) {
      toast.error('Bank is required!');
    } else if (selectedDataObj.bankAccountNumber.length === 0) {
      toast.error('Bank account number is required!');
    } else if (selectedDataObj.bankAccountName.length === 0) {
      toast.error('Bank account name is required!');
    } else if (selectedDataObj.bankBranchName.length === 0) {
      toast.error('Bank branch name is required!');
    } else if (selectedDataObj.note.length === 0) {
      toast.error('Note is required!');
    } else {
      const updatedData = { ...selectedDataObj };
      delete updatedData.addedBy;
      delete updatedData.lastUpdatedBy;
      dispatch(isAdd ? addSupplier(updatedData) : updateSupplier(updatedData));
    }
  };

  const uploadFiles = (file) => {
    return new Promise((resolve, reject) => {
      imageUpload(
        file,
        `SuppliersDocuments`,
        (data) => {
          if (data?.status) {
            resolve(data);
          } else {
            reject(new Error('Upload failed'));
          }
        },
        (errorMessage) => {
          console.error('Upload failed:', errorMessage);
          reject(new Error(errorMessage));
        }
      );
    });
  };

  return (
    <Page title={`${capitalize(type)} Suppliers List`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`${capitalize(type)} Suppliers List`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Supplier',
              href: '',
            },
            { name: `${capitalize(type)} Suppliers List` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New {capitalize(type)} Supplier
            </Button>
          }
        />

        {accessVerify('SupplierS_LIST_VIEW') ? (
          <DataGridTable
            name={'Suppliers List'}
            data={supplierList}
            column={columns}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            isRowClickable={true}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} Supplier</h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={2} sx={{ p: 1 }}>
                {/* Supplier Information Card */}
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Supplier Information
                  </Typography>
                  <Stack spacing={3}>
                    <TextField fullWidth label='Name' value={selectedDataObj?.name} onChange={(e) => updateEditingData('name', e.target.value)} />
                    <TextField fullWidth label='Supplier Code' value={selectedDataObj?.code} onChange={(e) => updateEditingData('code', e.target.value)} />
                    <TextField fullWidth label='Email' value={selectedDataObj?.email} onChange={(e) => updateEditingData('email', e.target.value)} />
                    <TextField fullWidth label='Phone' value={selectedDataObj?.phone} onChange={(e) => updateEditingData('phone', e.target.value)} />
                    <TextField fullWidth label='Address' value={selectedDataObj?.address} onChange={(e) => updateEditingData('address', e.target.value)} />
                  </Stack>
                </Card>

                {/* Contact Person Information Card */}
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Contact Person Information
                  </Typography>
                  <Stack spacing={3}>
                    <TextField fullWidth label='Contact Person Name' value={selectedDataObj?.contactPersonName} onChange={(e) => updateEditingData('contactPersonName', e.target.value)} />
                    <TextField fullWidth label='Contact Person Number' value={selectedDataObj?.contactPersonNumber} onChange={(e) => updateEditingData('contactPersonNumber', e.target.value)} />
                  </Stack>
                </Card>

                {/* Bank Information Card */}
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Bank Information
                  </Typography>
                  <Stack spacing={3}>
                    <TextField fullWidth label='Bank' value={selectedDataObj?.bank} onChange={(e) => updateEditingData('bank', e.target.value)} />
                    <TextField fullWidth label='Bank Account Number' value={selectedDataObj?.bankAccountNumber} onChange={(e) => updateEditingData('bankAccountNumber', e.target.value)} />
                    <TextField fullWidth label='Bank Account Name' value={selectedDataObj?.bankAccountName} onChange={(e) => updateEditingData('bankAccountName', e.target.value)} />
                    <TextField fullWidth label='Bank Branch Name' value={selectedDataObj?.bankBranchName} onChange={(e) => updateEditingData('bankBranchName', e.target.value)} />
                  </Stack>
                </Card>

                {/* Notes Card */}
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Notes
                  </Typography>
                  <TextField fullWidth label='Note' value={selectedDataObj?.note} onChange={(e) => updateEditingData('note', e.target.value)} />
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1} sx={{ p: 1 }}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%' }} onClick={handleSubmit}>
                      {isAdd ? 'Add Supplier' : 'Save Changes'}
                    </LoadingButton>
                    <Button
                      color='info'
                      variant='outlined'
                      size='large'
                      style={{ width: '100%' }}
                      onClick={() => {
                        manageModel(dataModel, 'add');
                      }}
                    >
                      Close
                    </Button>
                  </Stack>
                </Card>
                {selectedDataObj?.log?.length > 0 && (
                  <Card>
                    <Typography variant='subtitle1' sx={{ p: 3 }}>
                      History Information
                    </Typography>
                    <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                      <Timeline position='alternate'>
                        {selectedDataObj?.log?.map((value) => (
                          <TimelineItem key={value.id}>
                            <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
                              {utcMoment(value?.datetime).format('YYYY-MM-DD HH:mm:ss')}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineConnector />
                              <TimelineDot>
                                <Iconify icon='eva:checkmark-circle-2-fill' />
                              </TimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                              <Typography variant='body1' component='span'>
                                <b>{value?.description}</b>
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                      </Timeline>
                    </div>
                  </Card>
                )}
              </Stack>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
