import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  permissionsList: [],
  latestTableUpdate: {},
};

const slice = createSlice({
  name: 'Common',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    updateLatestTableUpdate(state, value) {
      state.latestTableUpdate = value.payload;
    },

    updatePermissions(state, value) {
      state.permissionsList = value.payload.data;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

export function getTablesRowCount() {
  return async () => {
    try {
      const response = await get(`getTablesRowCount`);
      if (response.status) {
        dispatch(slice.actions.updateLatestTableUpdate(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getPermissions() {
  return async () => {
    try {
      const response = await get(`permissions`);
      if (response.status) {
        dispatch(slice.actions.updatePermissions(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearAllCache() {
  return async () => {
    try {
      const response = await get(`cacheClear`);
      if (response.status) {
        dispatch(slice.actions.updatePermissions(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
