import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Container, Box, Typography, Alert } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { capitalize } from '../../../utils/common';
import DataGridTable from '../../../components/table/DataGridTable';

export default function SnapPayChecker() {
  const { type } = useParams();
  const { themeStretch } = useSettings();

  const [rowSelection, setRowSelection] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [soldPhoneListCsvData, setSoldPhoneListCsvData] = useState(null);
  const [snapPayDataCsvData, setSnapPayDataCsvData] = useState(null);
  const [bankStatementCsvData, setBankStatementCsvData] = useState(null);
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);

  const handleFileChange = (event, setFile, setData) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      if (!uploadedFile.name.endsWith('.csv')) {
        setError('Please upload a CSV file.');
        return;
      }
      setError('');
      setFile(uploadedFile);
    } else {
      setFile(null);
      setData([]);
    }
  };

  const handleRemoveFile = (setFile, setData) => {
    setFile(null);
    setData([]);
  };

  const parseAmount = (amount) => {
    if (typeof amount === 'string') {
      const cleaned = amount.replace(/,/g, '');
      const parsed = parseFloat(cleaned);
      return isNaN(parsed) ? 0 : parsed;
    }
    return 0;
  };

  const handleProcessData = async () => {
    setProcessing(true);
    setError('');

    if (!soldPhoneListCsvData || !snapPayDataCsvData || !bankStatementCsvData) {
      setError('Please upload all three CSV files.');
      setProcessing(false);
      return;
    }

    const parseCSV = async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async ({ target }) => {
          const csv = require('papaparse');
          const results = csv.parse(target.result, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          });

          if (results.errors.length > 0) {
            reject(results.errors);
          } else {
            const formattedData = results.data.map((row) => {
              const formattedRow = {};
              Object.keys(row).forEach((key) => {
                const lowerCamelKey = key
                  .split(/[_\s]+/)
                  .map((word, index) => (index === 0 ? word.charAt(0).toLowerCase() + word.slice(1).toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()))
                  .join('');
                formattedRow[lowerCamelKey] = row[key];
              });
              return formattedRow;
            });
            resolve(formattedData);
          }
        };

        reader.onerror = () => {
          reject(new Error('Failed to read the file.'));
        };
        reader.readAsText(file);
      });
    };

    // Parse all files
    const soldPhoneList = await parseCSV(soldPhoneListCsvData);
    const snapPayList = await parseCSV(snapPayDataCsvData);
    const bankPaymentList = await parseCSV(bankStatementCsvData);
    const paymentList = bankPaymentList?.filter((value) => {
      const description = value?.description;

      if (typeof description === 'string') {
        const lowerCaseDescription = description.toLowerCase();
        return lowerCaseDescription.startsWith('sp');
      } else {
        return false;
      }
    });

    console.log('soldPhoneList Data:', soldPhoneList[0]);
    console.log('snapPayList Data:', snapPayList[0]);
    console.log('paymentList:', paymentList[0]);

    const processedData = soldPhoneList?.map((soldPhone) => {
      const snapPayRecord = snapPayList?.find((snapPayRecord) => {
        return snapPayRecord?.refNumber === soldPhone?.spNo;
      });

      const paymentRefNum = typeof snapPayRecord?.paymentRefNum === 'string' ? snapPayRecord.paymentRefNum.replace(/\n/g, '') : snapPayRecord?.paymentRefNum;

      let bankPaymentNote = null;
      if (paymentRefNum && typeof paymentRefNum === 'string') {
        bankPaymentNote = paymentList?.find((bankPayment) => {
          return bankPayment?.description?.toLowerCase() === paymentRefNum.toLowerCase();
        });
      }

      return {
        ...soldPhone,
        payable: parseAmount(soldPhone?.payable),
        balanceAmount: parseAmount(soldPhone?.balanceAmount),
        snapPayRecord: snapPayRecord,
        paymentRefNum: paymentRefNum,
        totalBankTransferredAmount: bankPaymentNote?.Credits,
        bankPayment: bankPaymentNote,
      };
    });

    console.log(processedData?.filter((value) => value?.bankPayment));
    const groupedAndMoneyWeShouldGetBasedOnRefNumber = processedData.reduce((acc, item) => {
      const { paymentRefNum, payable } = item;

      if (!paymentRefNum) return acc;

      if (!acc[paymentRefNum]) {
        acc[paymentRefNum] = {
          paymentRefNum,
          totalPayable: 0,
        };
      }

      acc[paymentRefNum].totalPayable += Number(payable) || 0;

      return acc;
    }, {});

    const resultArray = Object.values(groupedAndMoneyWeShouldGetBasedOnRefNumber);
    const finalProcessedData = processedData?.map((value) => {
      const amount = groupedAndMoneyWeShouldGetBasedOnRefNumber[value?.paymentRefNum]?.totalPayable;
      return {
        ...value,
        moneyThatWeShouldRecieveForThisPay: amount,
        isAllMoneyEarned: amount === value?.totalBankTransferredAmount ? '' : 'Issue',
      };
    });

    setProcessedData(finalProcessedData);
    console.log(finalProcessedData?.filter((value) => value?.bankPayment));
  };
  const columns = !processedData?.[0]
    ? []
    : Object.keys(processedData[0])
        .map((key) => {
          if (['bankPayment', 'snapPayRecord'].includes(key)) return null;

          const commonProperties = {
            accessorKey: key,
            header: capitalize(key),
          };

          if (key === 'id') {
            return {
              ...commonProperties,
              header: 'Webhook Connection',
              Cell: ({ row }) => {
                return (
                  <ButtonGroup variant='text' aria-label='webhook button group'>
                    {row?.original?.type !== 'Instagram' ? (
                      row?.original?.isSubscribed ? (
                        <Tooltip title='Disconnect from Webhook'>
                          <Button
                            color='error'
                            startIcon={<Cancel />}
                            onClick={() => {
                              dispatch(manageSubscription(row?.original));
                            }}
                          >
                            Disconnect
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip title='Connect to Webhook'>
                          <Button
                            color='success'
                            startIcon={<CheckCircle />}
                            onClick={() => {
                              dispatch(manageSubscription(row?.original));
                            }}
                          >
                            Connect
                          </Button>
                        </Tooltip>
                      )
                    ) : (
                      <b>Always Connected</b>
                    )}
                  </ButtonGroup>
                );
              },
            };
          }

          if (key === 'type') {
            return {
              ...commonProperties,
              Cell: ({ row }) => {
                const typeValue = row?.original?.[key];
                const styles = {
                  Facebook: { color: '#1877F2', label: 'Facebook', icon: 'ic:twotone-facebook' },
                  Instagram: { color: '#E4405F', label: 'Instagram', icon: 'mdi:instagram' },
                  Whatsapp: { color: '#25D366', label: 'Whatsapp', icon: 'ic:twotone-whatsapp' },
                };
                const { color, label, icon } = styles[typeValue] || {};
                return (
                  <span style={{ color, fontWeight: 900 }}>
                    {icon && <Iconify icon={icon} />} {label || typeValue}
                  </span>
                );
              },
            };
          }

          if (key === 'status') {
            return {
              ...commonProperties,
              Cell: ({ row }) => {
                const isActive = row?.original?.[key] === 'active';
                return <span style={{ color: isActive ? '#00742a' : '#b80020', fontWeight: 900 }}>{isActive ? 'Active' : 'In-Active'}</span>;
              },
            };
          }
          return commonProperties;
        })
        .filter(Boolean);
  return (
    <Page title={`Snap Pay Payment Checker`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Snap Pay Payment Checker`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Snap Pay',
              href: '',
            },
            { name: `Snap Pay Payment Checker` },
          ]}
          action={
            <Link key={`add-new-link`} to={`/dashboard/foods/food/${type}/manage/new`}>
              <Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />}>
                New Food
              </Button>
            </Link>
          }
        />

        <Box sx={{ mb: 3, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
          <Typography variant='h6'>Upload CSV Files</Typography>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
            <Button variant='contained' component='label'>
              Sold Phone List
              <input type='file' hidden accept='.csv' onChange={(e) => handleFileChange(e, setSoldPhoneListCsvData)} />
            </Button>
            {soldPhoneListCsvData && (
              <>
                <Typography>{soldPhoneListCsvData.name}</Typography>
                <Button size='small' variant='outlined' color='error' onClick={() => handleRemoveFile(setSoldPhoneListCsvData)}>
                  Remove
                </Button>
              </>
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
            <Button variant='contained' component='label'>
              Snap Pay System Report
              <input type='file' hidden accept='.csv' onChange={(e) => handleFileChange(e, setSnapPayDataCsvData)} />
            </Button>
            {snapPayDataCsvData && (
              <>
                <Typography>{snapPayDataCsvData.name}</Typography>
                <Button size='small' variant='outlined' color='error' onClick={() => handleRemoveFile(setSnapPayDataCsvData)}>
                  Remove
                </Button>
              </>
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
            <Button variant='contained' component='label'>
              Bank Csv
              <input type='file' hidden accept='.csv' onChange={(e) => handleFileChange(e, setBankStatementCsvData)} />
            </Button>
            {bankStatementCsvData && (
              <>
                <Typography>{bankStatementCsvData.name}</Typography>
                <Button size='small' variant='outlined' color='error' onClick={() => handleRemoveFile(setBankStatementCsvData)}>
                  Remove
                </Button>
              </>
            )}
          </Box>

          <Button variant='contained' onClick={handleProcessData} sx={{ mt: 2 }}>
            {processing ? 'Processing...' : 'Process Data'}
          </Button>
          {error && (
            <Alert severity='error' sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </Box>

        <DataGridTable
          name={'Processed Data'}
          data={processedData}
          column={columns}
          onRowClick={(row) => {}}
          isRowClickable={false}
          isLoading={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowSelection={false}
          enableRowActions={false}
          renderRowActionItems={(value, closeMenu) => []}
        />
      </Container>
    </Page>
  );
}
