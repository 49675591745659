import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { dispatch } from '../../../../redux/store';
import { insertPurchaseNotesPaymentNote, updatePurchaseNoteStatus, uploadPurchaseNoteLinkedDocuments } from '../../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import Iconify from '../../../../components/Iconify';
import { handleNumber, safeJSONParse, utcMoment } from '../../../../utils/common';
import PdfButtons from '../../../../components/PdfButtons';
import { BASE_URL, COMPANY_DATA_FOR_PDF } from '../../../../config';
import React, { useState } from 'react';

export default function PurchaseNoteAction({ statusList = [], row, handleViewLog, selectedLocation, setItemAcceptDrawerOpen, openUploadImageWidget }) {
  const [anchorEl, setAnchorEl] = useState({ status: null, settings: null }); // Consolidate state for both menus

  const getItemsForInvoice = (parsedRowData) => {
    if (parsedRowData) {
      return parsedRowData?.map((value, index) => {
        const productData = safeJSONParse(value?.supplierPricing?.productData);
        const description = productData?.options?.map((option) => {
          return `${option?.productAttributeOptions?.productAttribute?.name} : ${option?.productAttributeOptions?.name} | `;
        });
        return {
          id: index,
          title: productData?.name,
          description: description,
          quantity: value?.qty,
          price: value?.supplierPricing?.price,
        };
      });
    }
  };

  const handleMenuToggle = (menuType, event) => {
    setAnchorEl((prevState) => ({
      ...prevState,
      [menuType]: prevState[menuType] ? null : event.currentTarget,
    }));
  };

  const handleStatusChange = (statusId, statusTitle) => {
    dispatch(
      updatePurchaseNoteStatus({
        id: row?.original?.id,
        status: statusId,
        title: statusTitle,
      })
    );
    setAnchorEl((prevState) => ({ ...prevState, status: null })); // Close status menu
  };

  return (
    <div style={{ minWidth: '100px' }}>
      <span
        style={{
          gap: '8px',
          padding: '5px',
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
          borderRadius: '10px',
          marginLeft: '5px',
        }}
      >
        {statusList?.filter((value) => row.original?.statusData?.allowedStatusTo?.split(',').includes(value?.id.toString()))?.length > 0 && (
          <>
            <Tooltip title={`Update Status`}>
              <IconButton style={{ color: '#00a39e' }} size='small' onClick={(event) => handleMenuToggle('status', event)}>
                <Iconify icon={'mdi:list-status'} />
              </IconButton>
            </Tooltip>

            <Menu anchorEl={anchorEl.status} open={Boolean(anchorEl.status)} onClose={() => setAnchorEl((prevState) => ({ ...prevState, status: null }))}>
              {statusList
                ?.filter((value) => row.original?.statusData?.allowedStatusTo?.split(',').includes(value?.id.toString()))
                .map((value) => (
                  <MenuItem key={value?.id} onClick={() => handleStatusChange(value?.id, value?.title)}>
                    <Iconify icon={value?.icon} />
                    &nbsp;&nbsp;{value?.title}
                  </MenuItem>
                ))}
            </Menu>
          </>
        )}

        {row.original?.statusData?.code === 'AWAITING_SHIPMENT' && (
          <Tooltip title={`Enter Received Products`}>
            <IconButton
              style={{ color: '#00a39e' }}
              size='small'
              onClick={(event) => {
                setItemAcceptDrawerOpen(true);
              }}
            >
              <Iconify icon={'lucide:package-plus'} />
            </IconButton>
          </Tooltip>
        )}

        <PdfButtons
          data={{
            fileName: `PN-${row.original?.id}`,
            invoiceNumber: `PN-${row.original?.id}`,
            createDate: utcMoment(row.original?.datetime).format('YYYY-MM-DD HH:mm:ss'),
            dueDate: utcMoment(row.original?.datetime).add(30, 'days').format('YYYY-MM-DD HH:mm:ss'),
            status: row.original?.status,
            discount: 0,
            taxes: 0,
            paidAmount: handleNumber(row.original?.paidAmount),
            totalPrice: handleNumber(row.original?.totalAmount) - handleNumber(row.original?.paidAmount),
            subTotalPrice: handleNumber(row.original?.totalAmount),
            topic: 'Purchase Note',
            invoiceFrom: {
              name: COMPANY_DATA_FOR_PDF?.name,
              address: selectedLocation?.address,
              phone: selectedLocation?.phone,
            },
            invoiceTo: {
              name: row.original?.supplier?.name,
              address: row.original?.supplier?.address,
              phone: row.original?.supplier?.phone,
            },
            items: getItemsForInvoice(row.original?.items),
            linkedDocuments: row.original?.linkedDocuments?.map((value) => {
              return `${BASE_URL}imageViewer?path=${btoa(unescape(encodeURIComponent(value?.url)))}` || [];
            }),
          }}
        />

        <Tooltip title={`Settings`}>
          <IconButton style={{ color: 'black' }} size='small' onClick={(event) => handleMenuToggle('settings', event)}>
            <Iconify icon={'mdi:dots-vertical'} />
          </IconButton>
        </Tooltip>

        <Menu anchorEl={anchorEl.settings} open={Boolean(anchorEl.settings)} onClose={() => setAnchorEl((prevState) => ({ ...prevState, settings: null }))}>
          <MenuItem
            onClick={() => {
              openUploadImageWidget(row.original);
              setAnchorEl((prevState) => ({ ...prevState, settings: null }));
            }}
          >
            <Iconify icon={'line-md:upload-loop'} />
            &nbsp;&nbsp;Upload Related Documents
          </MenuItem>
          <MenuItem
            onClick={() => {
              const note = prompt('Enter PurchaseNote Note:');
              if (note) {
                dispatch(insertPurchaseNotesPaymentNote({ id: row.original?.id, title: note }));
              }
              setAnchorEl((prevState) => ({ ...prevState, settings: null })); // Close settings menu
            }}
          >
            <Iconify icon={'material-symbols:add-ad-sharp'} />
            &nbsp;&nbsp;Add Log Note
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleViewLog(row?.original?.log);
              setAnchorEl((prevState) => ({ ...prevState, settings: null })); // Close settings menu
            }}
          >
            <Iconify icon={'material-symbols:history'} />
            &nbsp;&nbsp;View Log
          </MenuItem>
        </Menu>
      </span>
    </div>
  );
}
