import React, { useEffect, useState } from 'react';
import { Container, Drawer, Box, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { fCurrency } from '../../../utils/formatNumber';
import { getPurchaseNotes, getPurchaseNoteStatus } from '../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import { accessVerify, capitalize, utcMoment } from '../../../utils/common';
import PermissionRequired from '../../errorPages/permissionRequired';
import Iconify from '../../../components/Iconify';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import PurchaseNoteAction from './components/purchaseNoteAction';
import { useParams } from 'react-router-dom';
import AcceptShipmentDrawer from './components/acceptShipmentDrawer';
import LinkedDocumentsUploadDrawer from './components/linkedDocumentUploadDrawer';
import moment from 'moment';

export default function PurchaseNotes() {
  let { selectedStatusCode } = useParams();
  const { themeStretch } = useSettings();
  const [enteredPricing, setEnteredPricing] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [itemAcceptDrawerOpen, setItemAcceptDrawerOpen] = useState(false);
  const [itemAcceptDrawerData, setItemAcceptDrawerData] = useState({});
  const [logData, setLogData] = useState([]);

  const { data, addData, statusList } = useSelector((state) => state?.purchaseNotes);
  const { selectedLocation } = useSelector((state) => state.location);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [files, setFiles] = useState();

  useEffect(() => {
    dispatch(getPurchaseNotes());
    dispatch(getPurchaseNoteStatus());
  }, []);

  useEffect(() => {
    if (statusList) {
      setSelectedStatus(statusList?.find((status) => status?.code === selectedStatusCode));
    }
  }, [selectedStatusCode, statusList]);

  useEffect(() => {
    setEnteredPricing(data?.filter((value) => value?.status === selectedStatus?.id)?.map((value) => ({ ...value, status: value?.statusData?.title })));
  }, [selectedStatus, data]);

  useEffect(() => {
    if (addData?.data) {
      dispatch(getPurchaseNotes());
    }
  }, [addData]);

  const handleViewLog = (log) => {
    setLogData(log);
    setDrawerOpen(true);
  };
  const columns = !enteredPricing?.[0]
    ? []
    : Object.keys(enteredPricing?.[0])
        .map((value) => {
          if (['supplier', 'location', 'userData', 'items', 'linkedDocuments', 'locationId', 'userId', 'log', 'statusData'].includes(value)) {
            return null;
          } else if (value === 'supplierId') {
            return {
              accessorKey: 'supplierId',
              header: 'Invoice Id',
              Cell: ({ row }) => `PN-${row?.original?.id}`,
            };
          } else if (value === 'totalAmount') {
            return {
              accessorKey: 'totalAmount',
              header: 'Total Amount',
              Cell: ({ row }) => fCurrency(row?.original?.totalAmount),
            };
          } else if (value === 'paidAmount') {
            return {
              accessorKey: 'paidAmount',
              header: 'Paid Amount',
              Cell: ({ row }) => fCurrency(row?.original?.paidAmount),
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        .filter((value) => value);

  return (
    <Page title={`${selectedStatus?.title} Purchase Notes`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`${selectedStatus?.title} Purchase Notes`}
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Quotation', href: PATH_DASHBOARD.stocks.root }, { name: `${selectedStatus?.title} Purchase Notes` }]}
          action={[]}
        />
        {accessVerify('PURCHASE_NOTE_VIEW') ? (
          <DataGridTable
            name={'Purchase Notes'}
            data={enteredPricing}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={true}
            renderRowActionItems={(row) => {
              return [
                <PurchaseNoteAction
                  statusList={statusList}
                  row={row}
                  handleViewLog={handleViewLog}
                  selectedLocation={selectedLocation}
                  setDrawerOpen={setDrawerOpen}
                  openUploadImageWidget={(data) => {
                    setFiles({ ...data, time: moment().toString() });
                  }}
                  setItemAcceptDrawerOpen={() => {
                    setItemAcceptDrawerData(row?.original);
                    setItemAcceptDrawerOpen(true);
                  }}
                />,
              ];
            }}
          />
        ) : (
          <PermissionRequired />
        )}

        <Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <Box sx={{ width: 400, padding: 2 }}>
            <Typography variant='h6' gutterBottom>
              Purchase Note Log
            </Typography>
            <div style={{ maxHeight: '90vh', overflowY: 'auto' }}>
              <Timeline position='alternate'>
                {logData?.map((value) => (
                  <TimelineItem key={value.id}>
                    <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
                      {utcMoment(value?.datetime).format('YYYY-MM-DD HH:mm:ss')}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot>
                        <Iconify icon='eva:checkmark-circle-2-fill' />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                      <Typography variant='body1' component='span'>
                        <b>{value?.note}</b>
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </div>
          </Box>
        </Drawer>

        <AcceptShipmentDrawer data={itemAcceptDrawerData} itemAcceptDrawerOpen={itemAcceptDrawerOpen} setItemAcceptDrawerOpen={setItemAcceptDrawerOpen} />
        <LinkedDocumentsUploadDrawer data={files} setData={setFiles} />
      </Container>
    </Page>
  );
}
