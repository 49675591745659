import React, { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Box } from '@mui/material';
import { ImageUploadAndPreviewButton } from '../../../../utils/common';

const OptionForm = ({ open, onClose, onSave, option, optionModel }) => {
  const [optionData, setOptionData] = useState(optionModel);
  const [isImageUploading, setIsImageUploading] = useState(false);

  useEffect(() => {
    if (option) {
      setOptionData(option);
    } else {
      setOptionData(optionModel);
    }
  }, [option]);

  const handleChange = (key, value) => {
    setOptionData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSave = () => {
    onSave(optionData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>{option?.id ? 'Edit Option' : 'Add Option'}</DialogTitle>
      <DialogContent>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label='Name' value={optionData?.name} onChange={(e) => handleChange('name', e.target.value)} required />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label='Description' multiline rows={3} value={optionData?.description} onChange={(e) => handleChange('description', e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <ImageUploadAndPreviewButton
              fileName={`${optionData?.name}-icon`}
              width={500}
              height={500}
              format={['png', 'avif']}
              label='Icon / Logo'
              keyName='iconUrl'
              folderName='productAttributes'
              isImageUploading={isImageUploading}
              setIsImageUploading={setIsImageUploading}
              updateEditingData={handleChange}
              selectedDataObj={optionData}
            />
          </Grid>
          <Grid item xs={12}>
            <Box>
              <label style={{ marginRight: '8px', fontWeight: 'bold' }}>Color Code:</label>
              <input
                type='color'
                value={optionData?.colorCode}
                onChange={(e) => handleChange('colorCode', e.target.value)}
                style={{
                  border: 'none',
                  cursor: 'pointer',
                  width: '40px',
                  height: '40px',
                  padding: 0,
                  appearance: 'none',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  outline: 'none',
                  backgroundColor: optionData?.colorCode,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSave} color='primary' variant='contained'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OptionForm;
