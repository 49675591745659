function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  dashboard: {
    root: path(ROOTS_DASHBOARD, '/home'),
  },

  snapPayChecker: {
    root: path(ROOTS_DASHBOARD, '/SnapPayChecker'),
  },
  social: {
    root: path(ROOTS_DASHBOARD, '/social'),
    meta: {
      root: path(ROOTS_DASHBOARD, '/social/meta'),
      MetaConnect: path(ROOTS_DASHBOARD, '/social/meta/MetaConnect'),
      chat: path(ROOTS_DASHBOARD, '/social/meta/chat'),
    },
    callCenter: {
      root: path(ROOTS_DASHBOARD, '/social/callCenter'),
      manage: path(ROOTS_DASHBOARD, '/social/callCenter/manage'),
      logs: path(ROOTS_DASHBOARD, '/social/callCenter/logs'),
      abandonCalls: path(ROOTS_DASHBOARD, '/social/callCenter/abandonCalls'),
    },
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/products/list'),
    list: path(ROOTS_DASHBOARD, '/products/list'),
    category: path(ROOTS_DASHBOARD, '/products/category'),
    variations: path(ROOTS_DASHBOARD, '/products/variations'),
    tags: path(ROOTS_DASHBOARD, '/products/tags'),
    attributes: path(ROOTS_DASHBOARD, '/products/attributes'),
  },
  foods: {
    root: path(ROOTS_DASHBOARD, '/foods'),
    foodCategory: {
      root: path(ROOTS_DASHBOARD, '/foods/category'),
      ingredient: path(ROOTS_DASHBOARD, '/foods/category/ingredient'),
      precooked: path(ROOTS_DASHBOARD, '/foods/category/precooked'),
      menu: path(ROOTS_DASHBOARD, '/foods/category/menu'),
      variation: path(ROOTS_DASHBOARD, '/foods/category/variation'),
    },
    foodTypes: {
      root: path(ROOTS_DASHBOARD, '/foods/types'),
      ingredient: path(ROOTS_DASHBOARD, '/foods/types/ingredient'),
      precooked: path(ROOTS_DASHBOARD, '/foods/types/precooked'),
      menu: path(ROOTS_DASHBOARD, '/foods/types/menu'),
      variation: path(ROOTS_DASHBOARD, '/foods/types/variation'),
    },
  },
  suppliers: {
    root: path(ROOTS_DASHBOARD, '/suppliers'),
    stock: path(ROOTS_DASHBOARD, '/suppliers/stock'),
    assets: path(ROOTS_DASHBOARD, '/suppliers/assets'),
    services: path(ROOTS_DASHBOARD, '/suppliers/services'),
    assetsAndServices: path(ROOTS_DASHBOARD, '/suppliers/assetsAndServices'),
  },
  purchasing: {
    root: path(ROOTS_DASHBOARD, '/purchasing'),
    callAndGetPrices: path(ROOTS_DASHBOARD, '/purchasing/callAndGetPrices'),
    addToCart: path(ROOTS_DASHBOARD, '/purchasing/addToCart'),
    pendingPurchaseNotes: path(ROOTS_DASHBOARD, '/purchasing/purchaseNotes/PENDING'),
    approvedPurchaseNotes: path(ROOTS_DASHBOARD, '/purchasing/purchaseNotes/APPROVED'),
    awaitingShipment: path(ROOTS_DASHBOARD, '/purchasing/purchaseNotes/AWAITING_SHIPMENT'),
    receiving: path(ROOTS_DASHBOARD, '/purchasing/receiving'),
  },
  stocks: {
    root: path(ROOTS_DASHBOARD, '/stock'),
    singleStockItem: path(ROOTS_DASHBOARD, '/stock/single-stock-item'),
    viewAll: path(ROOTS_DASHBOARD, '/stock/view-all/stockOnly'),
    viewPOS: path(ROOTS_DASHBOARD, '/stock/view-all/posOnly'),
    wasteOnly: path(ROOTS_DASHBOARD, '/stock/view-all/waste'),
    usedOnly: path(ROOTS_DASHBOARD, '/stock/view-all/used'),
    food: path(ROOTS_DASHBOARD, '/stock/food'),
    foodCategory: path(ROOTS_DASHBOARD, '/stock/foodCategory'),
    supplier: path(ROOTS_DASHBOARD, '/stock/supplier'),
    viewTransfer: path(ROOTS_DASHBOARD, '/stock/view-transfer'),
    breaker: path(ROOTS_DASHBOARD, '/stock/breaker'),
    wasteManager: path(ROOTS_DASHBOARD, '/stock/wasteManager'),
    usedStockManager: path(ROOTS_DASHBOARD, '/stock/usedStockManager'),
    transfer: {
      root: path(ROOTS_DASHBOARD, '/stock/transfer'),
      view: path(ROOTS_DASHBOARD, '/stock/transfer/view'),
      sender: path(ROOTS_DASHBOARD, '/stock/transfer/sender'),
      receiver: path(ROOTS_DASHBOARD, '/stock/transfer/receiver'),
    },
    stockOrder: {
      root: path(ROOTS_DASHBOARD, '/stock/stockOrder'),
      view: path(ROOTS_DASHBOARD, '/stock/stockOrder/view'),
      create: path(ROOTS_DASHBOARD, '/stock/stockOrder/create'),
    },
    availabilityChecker: {
      checker: path(ROOTS_DASHBOARD, '/stock/availability/checker'),
    },
  },
  sales: {
    root: path(ROOTS_DASHBOARD, '/sales'),
    saleList: path(ROOTS_DASHBOARD, '/sales/saleList'),
    customerList: path(ROOTS_DASHBOARD, '/sales/customerList'),
  },
  hr: {
    root: path(ROOTS_DASHBOARD, '/hr'),
    employees: {
      root: path(ROOTS_DASHBOARD, '/hr/employees'),
      list: path(ROOTS_DASHBOARD, '/hr/employees/list/now'),
      formerList: path(ROOTS_DASHBOARD, '/hr/employees/list/former'),
    },
    salary: {
      root: path(ROOTS_DASHBOARD, '/hr/salary'),
      timeLog: path(ROOTS_DASHBOARD, '/hr/salary/timeLog'),
      generateSalary: path(ROOTS_DASHBOARD, '/hr/salary/generateSalary'),
    },
    inspection: {
      root: path(ROOTS_DASHBOARD, '/hr/inspection'),
      type: path(ROOTS_DASHBOARD, '/hr/inspection/typeList'),
      templateList: path(ROOTS_DASHBOARD, '/hr/inspection/inspectionTemplateList'),
      examin: path(ROOTS_DASHBOARD, '/hr/inspection/inspectionExamin'),
    },
    rost: {
      manager: path(ROOTS_DASHBOARD, '/hr/rost/manager'),
    },
    leave: {
      manager: path(ROOTS_DASHBOARD, '/hr/leave/manager/all'),
    },
    report: {
      root: path(ROOTS_DASHBOARD, '/hr/report'),
    },
  },
  kitchen: {
    root: path(ROOTS_DASHBOARD, '/kitchen'),
    cookingStatus: path(ROOTS_DASHBOARD, '/kitchen/cooking'),
    cookingProcess: path(ROOTS_DASHBOARD, '/kitchen/cookingProcess'),
    store: path(ROOTS_DASHBOARD, '/kitchen/store'),
    display: path(ROOTS_DASHBOARD, '/kitchen/display'),
  },
  marketing: {
    root: path(ROOTS_DASHBOARD, '/marketing'),
    discounts: path(ROOTS_DASHBOARD, '/marketing/coupons/discount'),
    vouchers: path(ROOTS_DASHBOARD, '/marketing/coupons/voucher'),
  },
  assets: {
    root: path(ROOTS_DASHBOARD, '/assets'),
    list: path(ROOTS_DASHBOARD, '/assets/list'),
    categories: path(ROOTS_DASHBOARD, '/assets/categories'),
  },
  finance: {
    root: path(ROOTS_DASHBOARD, '/finance'),
    cashflow: path(ROOTS_DASHBOARD, '/finance/cashflow'),
    cashflowTransfers: path(ROOTS_DASHBOARD, '/finance/cashflowTransfers'),
    cashflowCategories: path(ROOTS_DASHBOARD, '/finance/cashflowCategories'),
    paymentTypes: path(ROOTS_DASHBOARD, '/finance/paymentTypes'),
  },
  setting: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    general: {
      root: path(ROOTS_DASHBOARD, '/settings/general'),
      locations: path(ROOTS_DASHBOARD, '/settings/general/locations'),
    },
    account: {
      root: path(ROOTS_DASHBOARD, '/settings/account'),
      ownAccount: path(ROOTS_DASHBOARD, '/settings/account/userAccount/own'),
    },
  },

  // Other line of business.
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings/locations'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chatComponents'),
    new: path(ROOTS_DASHBOARD, '/chatComponents/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
