import React, { useState, useEffect } from 'react';
import { Box, Card, Grid, List, ListItem, SwipeableDrawer, Typography, Button } from '@mui/material';
import { AnimatePresence, m } from 'framer-motion';
import { UploadMultiFile } from '../../../../components/upload';
import Image from '../../../../components/Image';
import { Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { toast } from 'react-toastify';
import { BASE_URL, ENV } from '../../../../config';

const LinkedDocumentsUploadDrawer = ({ data, setData }) => {
  const [files, setFiles] = useState([]);
  const [croppedImages, setCroppedImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [documentGallery, setDocumentGallery] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [editingImageIndex, setEditingImageIndex] = useState(null);

  useEffect(() => {
    console.log(data);
    if (data) {
      setIsModelOpen(true);
      setDocumentGallery(data?.linkedDocuments?.map((value) => value?.url) || []);
    }
  }, [data]);

  const isImageFile = (file) => {
    const validTypes = ['image/jpeg', 'image/png'];
    return validTypes.includes(file.type);
  };

  const handleImageUpload = async () => {
    if (croppedImages.length === 0) {
      toast.error('No images cropped.');
      return;
    }

    const toastId = toast.loading('Uploading...');

    try {
      const formData = new FormData();

      for (let i = 0; i < croppedImages.length; i++) {
        const fileBlob = croppedImages[i];
        const fileType = fileBlob.type;
        const fileName = `image-${i}.${fileType.split('/')[1]}`;

        console.log('Appending file:', fileBlob);
        formData.append('files', fileBlob, fileName);
        formData.append('tag', 'Uploadedrs');
        formData.append('folderName', 'Uploadader');
        formData.append('subFolder', 'Uploaddser');
      }
      if (BASE_URL.includes('localhost')) {
        formData.append('tag', `DELETABLE`);
      }

      const response = await fetch(`${BASE_URL}common/imageUploadBlob`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      const data = await response.json();
      console.log('Upload response:', data);

      if (data?.status) {
        toast.update(toastId, {
          render: 'Images Uploaded Successfully!',
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
          closeButton: true,
          isLoading: false,
        });
      } else {
        toast.update(toastId, {
          render: 'Image Upload Failed',
          type: toast.TYPE.ERROR,
          autoClose: 2000,
          closeButton: true,
          isLoading: false,
        });
      }
    } catch (error) {
      toast.update(toastId, {
        render: 'Image Upload Failed',
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: true,
        isLoading: false,
      });
      console.error('Error during upload:', error);
    }
  };

  const onChange = (cropper, index) => {
    const canvas = cropper.getCanvas();
    if (canvas) {
      canvas.toBlob((blob) => {
        if (blob) {
          const newCroppedImages = [...croppedImages];
          newCroppedImages[index] = blob;
          setCroppedImages(newCroppedImages);
        } else {
          console.error('Failed to convert canvas to blob');
        }
      });
    }
  };

  const handleFileDrop = (uploadedFiles) => {
    setPreviewImage(null);
    const validFiles = uploadedFiles.filter(isImageFile);
    setFiles((value) => [
      ...value,
      ...validFiles.map((file, index) => ({
        id: index,
        preview: URL.createObjectURL(file),
        isUploading: false,
      })),
    ]);
    setEditingImageIndex(0);
  };

  const isUploadEnabled = croppedImages.length === files.length && !croppedImages.includes(null);

  return (
    <SwipeableDrawer
      anchor={'top'}
      open={isModelOpen}
      onOpen={() => setIsModelOpen(true)}
      onClose={() => setIsModelOpen(false)}
      PaperProps={{
        sx: {
          width: '90%',
          height: '90%',
          marginLeft: '5%',
          marginTop: '3%',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: 3,
        },
      }}
    >
      <Box sx={{ mt: 1, mb: 2 }}>
        <Typography
          variant='h6'
          sx={{
            fontWeight: 600,
            color: '#333',
            textTransform: 'uppercase',
            letterSpacing: 1.5,
          }}
        >
          Accept Items of Purchase Note
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Card sx={{ p: 3 }}>
            <Typography variant='subtitle1' padding='10px 0'>
              Related Documents
            </Typography>
            <UploadMultiFile
              error={false}
              showPreview={true}
              files={files}
              onRemove={(removedFile) => {
                setFiles(files.filter((file) => file !== removedFile));
                setCroppedImages(croppedImages.filter((_, index) => index !== removedFile.id));
              }}
              onRemoveAll={() => {
                setFiles([]);
                setCroppedImages([]);
              }}
              helperText={''}
              onUpload={handleImageUpload}
              isUploadEnabled={isUploadEnabled}
              onDrop={handleFileDrop}
              isUploading={isUploading}
              setEditingImageIndex={(value) => {
                setPreviewImage(null);
                setEditingImageIndex(value);
              }}
            />

            {documentGallery?.length > 0 && (
              <Typography variant='subtitle1' padding='10px 0'>
                Uploaded Images
              </Typography>
            )}
            <List disablePadding sx={{ my: 3, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              <AnimatePresence>
                {documentGallery
                  ?.filter((value) => value)
                  .map((src, index) => (
                    <ListItem
                      key={index}
                      component={m.div}
                      onClick={() => {
                        console.log(src);
                        setPreviewImage(src);
                      }}
                      sx={{
                        p: 0,
                        m: 0.5,
                        width: '30%',
                        borderRadius: 1.25,
                        overflow: 'hidden',
                        position: 'relative',
                        cursor: 'pointer',
                        display: 'inline-flex',
                        border: (theme) => `solid 1px ${theme.palette.divider}`,
                        '&:hover': {
                          boxShadow: 3,
                          transition: 'box-shadow 0.3s',
                        },
                      }}
                    >
                      <Image
                        alt='preview'
                        src={src}
                        style={{
                          objectFit: 'cover',
                          width: '100%',
                        }}
                      />
                    </ListItem>
                  ))}
              </AnimatePresence>
            </List>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          {previewImage ? (
            <Image alt='previewImage' src={previewImage} style={{ width: '100%' }} />
          ) : (
            editingImageIndex !== null &&
            files[editingImageIndex] && (
              <Cropper
                src={files[editingImageIndex].preview}
                onChange={(cropper) => onChange(cropper, editingImageIndex)}
                style={{
                  height: '75vh',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                }}
                stencilProps={{
                  aspectRatio: 2481 / 3508,
                }}
              />
            )
          )}
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
};

export default LinkedDocumentsUploadDrawer;
