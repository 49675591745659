import React from 'react';
import { Grid, Card, TextField, Typography, Switch, Tooltip } from '@mui/material';
import CategorySelector from '../../../../../components/selector/CategorySelector';
import QuillTextEditor from '../../../../../components/textEditor/QuillEditor';
import { capitalizeAndEndWithPeriod } from '../../../../../utils/common';

export default function ProductInformationForm({ selectedDataObj, updateEditingData, productCategoryList }) {
  return (
    <Card sx={{ p: 3 }} style={{ height: '100%' }}>
      <Typography variant='subtitle1' padding='10px 0'>
        Product Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label='Name' value={selectedDataObj?.name || ''} onChange={(e) => updateEditingData('name', capitalizeAndEndWithPeriod(e.target.value))} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label='Short Description' value={selectedDataObj?.shortDescription || ''} onChange={(e) => updateEditingData('shortDescription', e.target.value)} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField fullWidth label='Lowest Selling Price' value={selectedDataObj?.lowestSellingPrice || ''} onChange={(e) => updateEditingData('lowestSellingPrice', e.target.value)} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField fullWidth label='MRP Price' value={selectedDataObj?.mrpPrice || ''} onChange={(e) => updateEditingData('mrpPrice', e.target.value)} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField fullWidth label='Max Purchasing Price' value={selectedDataObj?.maxPurchasingPrice || ''} onChange={(e) => updateEditingData('maxPurchasingPrice', e.target.value)} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CategorySelector
            value={selectedDataObj?.categoryId}
            onCategorySelect={(id) => {
              const selectedCategory = productCategoryList?.find((item) => item.id === Number(id));
              updateEditingData('categoryId', selectedCategory?.id);
            }}
          />
        </Grid>
        {selectedDataObj?.childrenProducts?.length === 0 && selectedDataObj?.parentId === 'parent' && (
          <Grid item xs={12} md={6}>
            <Tooltip title='Does this product have variations? (e.g., Mobile phone with 4GB / 8GB RAM versions)' placement='top'>
              <Typography variant='overline' sx={{ cursor: 'help', mr: 1.5 }}>
                Has Variations
              </Typography>
            </Tooltip>
            <Switch checked={selectedDataObj?.hasVariations} onChange={(e) => updateEditingData('hasVariations', e.target.checked)} />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Tooltip title='Is this product available on the website for customers to see and buy?' placement='top'>
            <Typography variant='overline' sx={{ cursor: 'help', mr: 1.5 }}>
              Available on Web
            </Typography>
          </Tooltip>
          <Switch checked={selectedDataObj?.isAvailableOnWeb} onChange={(e) => updateEditingData('isAvailableOnWeb', e.target.checked)} />
        </Grid>
        <Grid item xs={12} md={12}>
          <QuillTextEditor label='Description' value={selectedDataObj?.description || ''} onChange={(content) => updateEditingData('description', content)} />
        </Grid>
      </Grid>
    </Card>
  );
}
