import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

// ----------------------------------------------------------------------

export default function HeaderBreadcrumbs({ links = [], action, heading = '', moreLink = '' || [], sx, ...other }) {
  return (
    <Box sx={{ mb: 2, ...sx }}>
      <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant='h5'
            sx={{
              fontWeight: 700,
              color: '#333',
              textTransform: 'capitalize',
              letterSpacing: 0.5,
            }}
          >
            {heading}
          </Typography>
          {/*<Breadcrumbs links={links} {...other} />*/}
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>
      <Divider />
    </Box>
  );
}
