import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Chip, Grid, Stack, SwipeableDrawer, TextField, Typography, Paper, Divider } from '@mui/material';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { safeJSONParse } from '../../../../utils/common';
import { toast } from 'react-toastify';
import { addStock } from '../../../../redux/slices/stockManagementRedux/stockRedux';
import { dispatch, useSelector } from '../../../../redux/store';

export default function AcceptShipmentDrawer({ data, itemAcceptDrawerOpen, setItemAcceptDrawerOpen }) {
  const [selectedDataObj, setSelectedDataObj] = useState([]);
  const [currentEnteringObject, setCurrentEnteringObject] = useState(null);
  const { selectedLocation } = useSelector((state) => state.location);

  useEffect(() => {
    setSelectedDataObj({
      ...data,
      items: data?.items?.map((item) => ({
        ...item,
        receivedBarcodes: item?.receivedBarcodes || [],
      })),
    });
  }, [data, itemAcceptDrawerOpen]);

  const addReceiving = (barcode) => {
    const updatedData = { ...selectedDataObj };

    if (updatedData.items.some((item) => item.receivedBarcodes.includes(barcode))) {
      toast.error('Barcode already entered!');
      return;
    }

    const itemToUpdate = updatedData.items.find((item) => item.id === currentEnteringObject.id);

    if (itemToUpdate.receivedBarcodes.length >= itemToUpdate.qty) {
      toast.error(`You cannot add more than ${itemToUpdate.qty} barcodes for this item.`);
      return;
    }

    updatedData.items = updatedData.items.map((item) => {
      if (item.id === currentEnteringObject.id) {
        return {
          ...item,
          receivedBarcodes: [...item.receivedBarcodes, barcode],
        };
      }
      return item;
    });

    setSelectedDataObj(updatedData);
  };

  const removeBarcode = (barcode) => {
    const updatedData = { ...selectedDataObj };
    updatedData.items = updatedData.items.map((item) => {
      if (item.id === currentEnteringObject.id) {
        return {
          ...item,
          receivedBarcodes: item.receivedBarcodes.filter((bc) => bc !== barcode),
        };
      }
      return item;
    });
    setSelectedDataObj(updatedData);
  };

  const handleBarcodeSubmit = (event) => {
    if (event.key === 'Enter') {
      const barcode = event.target.value;
      addReceiving(barcode);
      event.target.value = '';
    }
  };

  const getItemDetails = (value) => {
    const productData = safeJSONParse(value?.supplierPricing?.productData);
    const description = productData?.options?.map((option) => {
      const productAttributes = option?.productAttributeOptions?.productAttribute;
      return `${option?.productAttributeOptions?.productAttribute?.name} : ${productAttributes?.preUnit} ${option?.productAttributeOptions?.name} ${productAttributes?.postUnit} | `;
    });
    return (
      <>
        <Typography variant='subtitle1' sx={{ fontWeight: 600, color: '#333' }}>
          {productData?.name} (Ordered {value?.qty}) (Received {value?.receivedBarcodes?.length})
        </Typography>
        <Typography variant='p' sx={{ fontSize: '12px', fontWeight: 400, paddingBottom: 2, color: '#7e7e7e' }}>
          {description}
        </Typography>
        <br />
      </>
    );
  };

  const isStockUpdatedEnabled = () => {
    return selectedDataObj?.items?.every((item) => item.receivedBarcodes.length === item.qty);
  };

  const submitReceiving = () => {
    if (confirm('Are you sure you want to update stock with receiving?')) {
      dispatch(addStock({ location: selectedLocation, data: selectedDataObj }));
    }
  };

  return (
    <SwipeableDrawer
      anchor={'top'}
      open={itemAcceptDrawerOpen}
      onOpen={() => setItemAcceptDrawerOpen(true)}
      onClose={() => setItemAcceptDrawerOpen(false)}
      PaperProps={{
        sx: {
          width: '90%',
          height: '90%',
          marginLeft: '5%',
          marginTop: '3%',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: 3,
        },
      }}
    >
      <Box sx={{ mt: 1, mb: 2 }}>
        <Typography
          variant='h6'
          sx={{
            fontWeight: 600,
            color: '#333',
            textTransform: 'uppercase',
            letterSpacing: 1.5,
          }}
        >
          Accept Items of Purchase Note (PN-{selectedDataObj?.id})
        </Typography>

        <Typography variant='body1' sx={{ fontWeight: 700, color: '#1976d2' }}>
          From {selectedDataObj?.supplier?.name} | On {moment(selectedDataObj?.datetime).format('DD MMMM YYYY')}
        </Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Stack spacing={3}>
            {selectedDataObj?.items?.map((value, index) => (
              <Card
                key={index}
                sx={{
                  p: 3,
                  cursor: 'pointer',
                  backgroundColor: currentEnteringObject?.id === value?.id ? '#f1f8ff' : '#fafafa',
                  borderRadius: 2,
                  boxShadow: 1,
                  '&:hover': {
                    backgroundColor: '#e3ebf3',
                    boxShadow: 3,
                    transition: 'background-color 0.3s, box-shadow 0.3s',
                  },
                  transition: 'background-color 0.3s, box-shadow 0.3s',
                }}
                onClick={() => {
                  setCurrentEnteringObject(value);
                }}
              >
                {getItemDetails(value)}

                {value?.receivedBarcodes?.map((barcode, index) => (
                  <Chip
                    key={index}
                    label={`${barcode}`}
                    onClick={() => removeBarcode(barcode)}
                    sx={{
                      m: 0.5,
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#1565c0',
                      },
                      transition: 'background-color 0.3s',
                    }}
                  />
                ))}
              </Card>
            ))}
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          {currentEnteringObject && (
            <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 2 }}>
              <Typography variant='subtitle1' sx={{ fontWeight: 600, paddingBottom: 2 }}>
                <span style={{ color: 'grey' }}>Selected:</span> <b>{safeJSONParse(currentEnteringObject?.supplierPricing?.productData)?.name}</b>
              </Typography>
              <TextField label={`Scan Barcode Here`} fullWidth variant='outlined' sx={{ mb: 3 }} onKeyDown={handleBarcodeSubmit} />
              <Stack spacing={2}>
                <LoadingButton
                  variant='contained'
                  size='large'
                  sx={{ width: '100%', backgroundColor: '#1976d2', '&:hover': { backgroundColor: '#1565c0' } }}
                  onClick={submitReceiving}
                  disabled={!isStockUpdatedEnabled()}
                >
                  Update stock with receiving
                </LoadingButton>
                <Button color='info' variant='outlined' size='large' sx={{ width: '100%' }} onClick={() => setItemAcceptDrawerOpen(false)}>
                  Close
                </Button>
              </Stack>
            </Paper>
          )}
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
}
